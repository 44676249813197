<template>
    <header class="bg-buff-600 text-black p-4 shadow-md pt-8">
      <div class="container mx-auto flex justify-between items-center">
        <!-- Navigation Links -->
        <nav class="hidden md:flex space-x-6">
        <RouterLink to="/" class="font-nunito hover:text-red-300">Startseite</RouterLink>
        <RouterLink to="/services" class="font-nunito hover:text-red-300">Serviceleistungen</RouterLink>
        </nav>
    </div>
    <!-- Hamburger Icon (Visible only on mobile) -->
    <button @click="toggleSidebar" class="p-2 block md:hidden">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </button>

    <!-- Sidebar (Visible only on mobile) -->
    <transition name="slide">
      <div v-if="isSidebarOpen" class="fixed left-0 top-0 w-64 h-full bg-pink-800 text-white p-4 transform transition-transform md:hidden">
        <button @click="toggleSidebar" class="text-white absolute top-4 right-4">
          &times;
        </button>
        <h2 class="font-nunito text-xl font-bold mb-4">PAnalytics.de</h2>
        <ul>
          <li class="mb-2"></li><RouterLink to="/"  @click="closeSidebar" class="font-nunito block py-2 px-4 hover:bg-gray-700 rounded">Startseite</RouterLink>
          <li class="mb-2"><RouterLink to="/services"   @click="closeSidebar" class="font-nunito block py-2 px-4 hover:bg-gray-700 rounded">Serviceleistungen</RouterLink></li>
        </ul>
      </div>
    </transition>
    </header>
    
  </template>
  
  <script>
//import { RouterLink } from 'vue-router';

  export default {
    name: "TopBar",
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  methods: {
    closeSidebar() {
      this.isSidebarOpen = false; // Closes the sidebar when a link is clicked
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
  </script>
  
  <style scoped>
  /* Optional slide transition */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
  </style>
  