<template>
  <Row>
    <div class="flex items-center justify-center">
      <div class="relative z-10 text-center w-2/3">
        <h1 class="font-nunito text-black sm:text-6xl md:text-6xl lg:text-6xl font-extrabold drop-shadow-lg typing-animation leading-tight pt-10">
          {{ texts[currentIndex] }}
      </h1>
      <h1 class="font-nunito text-black sm:text-6xl md:text-6xl lg:text-6xl font-extrabold drop-shadow-lg leading-tight  pb-10">
        schneller und präziser mit KI-Unterstützung erstellen.
      </h1>
     
      </div>
    </div>
  </Row>
  <Row>
    <div class="flex flex-wrap flex-col md:flex-row md:mx-0 mx-auto items-stretch">
      <!--ApoCard class="font-nunito w-72 h-32  content-center" :iconPath="require('@/assets/location.svg')" title="Adresse">Kölner Str. 6-10 <br> 50126 Bergheim</ApoCard-->
      <!--ApoCard class="font-nunito w-72 h-32 content-center" :iconPath="require('@/assets/pill.svg')" title="Reservierung">Unverbindliche Arzneimittel Reservierung</ApoCard-->
      <ApoCard class="font-nunito w-72 h-32 content-center" :iconPath="require('@/assets/phone.svg')" title="Kontakt">
        <a href="tel:+4915140300787" class="font-nunito underline text-orange-600 hover:text-blue-800 visited:text-red-600">+49 (0)151 - 40300787</a>
        <br>
        <a href="mailto:info@panalytics.de" class="font-nunito underline text-orange-600 hover:text-blue-800 visited:text-red-600">info@panalytics.de</a>
      </ApoCard>
    </div>
  </Row>
</template>
<script setup>
import ApoCard from './ApoCard.vue';
import {ref, onMounted, onUnmounted} from 'vue';

const texts = ref([
  "Clinical Trial Protocols",
  "Einwilligungserklärung",
  "Case Report Forms",
  "Monitoring-Berichte",
]);
const currentIndex = ref(0);

let interval = null;

onMounted(() => {
  interval = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % texts.value.length;
  }, 5000); // Change text every 3 seconds
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style scoped>
@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.typing-animation {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #fff; /* Creates the cursor effect */
  animation: typing 4s steps(30) 1s forwards, blink 0.75s step-end infinite;
}

/* Optional: Cursor blinking effect */
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>

