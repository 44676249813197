import { createRouter, createWebHistory } from 'vue-router';
import ApoMain from '@/components/ApoMain.vue';
import ApoServices from '@/components/ApoServices.vue';
import ApoImpressum from '@/views/ApoImpressum.vue';

const routes = [
  {
    path: '/',
    component: ApoMain,
  },
  {
    path: '/services',
    component: ApoServices
  },
  {
    path: '/imprint',
    component: ApoImpressum
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // `savedPosition` is used for browser's back/forward button navigation
    if (savedPosition) {
      return savedPosition; // Scroll to the saved position
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

export default router;
