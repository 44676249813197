<template>
    <div class="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 class="text-2xl font-bold mb-4">Impressum</h1>
      
      <div class="mb-4">
        <h2 class="font-semibold">Angaben gemäß § 5 TMG:</h2>
        <p><strong>{{ name }}</strong></p>
        <p>{{ address }}</p>
        <p>{{ city }}</p>
      </div>
      
      <div class="mb-4">
        <h2 class="font-semibold">Kontakt:</h2>
        <p>Telefon: {{ phone }}</p>
        <p>E-Mail: <a :href="`mailto:${email}`" class="text-blue-500">{{ email }}</a></p>
        <p>Website: <a :href="website" class="text-blue-500">{{ website }}</a></p>
      </div>
      
      <div class="mb-4">
        <h2 class="font-semibold">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
        <p><strong>{{ name }}</strong></p>
        <p>{{ address }}</p>
      </div>
      
      <div class="mt-6 text-sm text-gray-700">
        <h2 class="font-semibold">Haftungsausschluss:</h2>
        <p>
          Die Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Für die Richtigkeit, 
          Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden.
        </p>
      </div>
      
      <div class="mt-4 text-sm text-gray-700">
        <h2 class="font-semibold">Haftung für Links:</h2>
        <p>
          Diese Webseite enthält Links zu externen Websites Dritter, auf deren Inhalte ich keinen Einfluss habe. 
          Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen.
        </p>
      </div>
      
      <div class="mt-4 text-sm text-gray-700">
        <h2 class="font-semibold">Urheberrecht:</h2>
        <p>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf dieser Webseite unterliegen dem deutschen Urheberrecht. 
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts 
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        </p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useHead } from '@vueuse/head';

  useHead({
    meta: [
      { name: 'robots', content: 'noindex, nofollow' }
    ] 
    });
  
  const name = ref('Haider Al Bassam');
  const address = ref('');
  const city = ref('50259, Pulheim');
  const phone = ref('015140300787');
  const email = ref('info@panalytics.de');
  const website = ref('https://www.panalytics.de');
  </script>