import { createApp } from 'vue'
import App from './App.vue'
import LenisVue from 'lenis/vue'
import './assets/tailwind.css'
import "@fontsource/roboto"; 
import "@fontsource/nunito"; 
import router from './router';


const app = createApp(App);
app.use(LenisVue);
app.use(router);
app.mount('#app')

