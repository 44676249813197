<template>
    <footer class="bg-primary-800 text-black py-6 mt-10">
      <div class="flex justify-center">

      </div>
      <div class="flex justify-center">
        <nav class="justify-left space-x-4 mt-4">
          <router-link class="font-nunito hover:text-orange-400" to="/dataprivacy">Datenschutz</router-link>
          <router-link class="font-nunito hover:text-orange-400" to="/imprint">Impressum</router-link>
          <!--a href="#" class="hover:text-gray-400">Privacy Center</a-->
        </nav>
      </div>
    </footer>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  </style>
  